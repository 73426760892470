import React from 'react';
import { Link, useLocation } from "react-router-dom";
import ShowDate from './Date';
import './index.css';

const Header = () => {
  const location = useLocation();
  return (
    <div className='header'>
      <div className='header-title'>昆山昆越环境技术有限公司</div>
      <div className='link-wrapper'>
        <Link className={location.pathname === '/' ? 'selected link-item' : 'link-item'} to="/">首页</Link>
        <Link className={location.pathname === '/announcement' ? 'selected link-item' : 'link-item'} to="/announcement">公告</Link>
      </div>
      <ShowDate />
    </div>
  );
};

export default Header;