import React, { useEffect, useState } from 'react';
import './index.css';

const getDate = () => {
  const now = new Date();
  const year = now.getFullYear(); //得到年份
  const month = now.getMonth()+1;//得到月份
  const  date = now.getDate();//得到日期
  const hour= now.getHours();//得到小时数
  const minute= now.getMinutes();//得到分钟数
  const second= now.getSeconds();//得到秒数
  return `${year}-${month < 10 ? '0' + month : month}-${date < 10 ? '0' + date : date} ${hour < 10 ? '0' + hour : hour}:${minute < 10 ? '0' + minute : minute}:${second < 10 ? '0' + second : second}`;
}

const ShowDate = () => {
  const [date, setDate] = useState(getDate());
  useEffect(() => {
    let interval = null;
    const inter = () => {
      setDate(getDate());
      interval = setTimeout(inter, 1000);
    }
    inter();
    return () => {
      clearTimeout(interval)
    }
  }, []);
  return (
    <div className='header-date'>
      {date}
    </div>
  )
}

export default ShowDate;