import React from "react";
import publicImg from '../asset/public_security_icon.png';
import './index.css';
const Footer = () => {
  return (
    <div className='home-fourth'>
      <div className='home-fourth-f' style={{ marginTop: 10}}>
        <img src={publicImg} className='home-fourth-f-img' alt='' style={{ verticalAlign: 'middle'}}></img>
        <span className="public-security-reference">苏公网安备 32058302003454号</span>
      </div>
      <div style={{ color: '#a6a6a6', fontSize: '14px', marginTop: 10}}>©2023 - 2024 kunshankunyue.com版权所有</div>
      <div className="icp-reference"
       onClick={() => window.open('https://beian.miit.gov.cn', true)}>苏ICP备2023022849号-1</div>
    </div>
  );
};

export default Footer;