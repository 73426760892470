import * as React from "react";
import {
  createBrowserRouter,
  RouterProvider
} from "react-router-dom";
import Home from "./home";
import Announcement from "./announcement";
import AnnouncementDetail from "./announcementDetail";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Home />,
  },
  {
    path: "announcement",
    element: <Announcement />,
  },
  {
    path: "announcement-detail/:name",
    element: <AnnouncementDetail />,
  },
]);

function App() {
  return (
    <div className="App">
      <RouterProvider router={router} />
    </div>
  );
}

export default App;
