import React from 'react';
import Header from '../header';
import './index.css';
import threeImg from '../asset/3.jpeg';
import Footer from '../footer';

const Home = () => {
  return (
    <div>
      <Header />
      <div>
        <div className='home-nav'>
          <div className='home-nav-content'>
            <div className='home-nav-f'>LOVE  &nbsp;OUR  &nbsp;HOME</div>
            <div className='home-nav-s'>珍惜家园 &nbsp;&nbsp;爱护环境</div>
            <div className='home-nav-t'>CARE FOR THE ENVIRONMENT</div>
          </div>
        </div>
        <div className='home-section'>
          <div className='home-section-l'>
            <div className='home-section-l-title'>企业介绍</div>
            <div className='home-section-l-sub'>Enterprise introduction</div>
            <div className='home-section-l-place'></div>
            <div className='home-section-l-text'>昆山昆越环境技术有限公司成立于2021年09月29日，注册地位于昆山市张浦镇新吴街888号海尚商务广场1号楼150室，法定代表人为韩东群。经营范围包括许可项目：建设工程施工；消防设施工程施工（依法须经批准的项目，经相关部门批准后方可开展经营活动，具体经营项目以审批结果为准）一般项目：大气环境污染防治服务；水环境污染防治服务；土壤环境污染防治服务；环保咨询服务；园林绿化工程施工；工程管理服务；机械电气设备销售；环境保护专用设备销售；仪器仪表销售；建筑材料销售；办公用品销售；劳动保护用品销售；金属材料销售；专用化学产品销售（不含危险化学品）；社会稳定风险评估；技术服务、技术开发、技术咨询、技术交流、技术转让、技术推广（除依法须经批准的项目外，凭营业执照依法自主开展经营活动）</div>
          </div>
          <img src={threeImg} className='home-section-r' alt=''></img>
        </div>
        <div className='home-third'>
          <div className='home-third-f'></div>
          <div className='home-third-s'></div>
        </div>
        <Footer />
      </div>
    </div>
  );
};

export default Home;